import { graphql, useStaticQuery } from 'gatsby';

import Layout from 'layouts/default';

import { BasicContainer } from 'components/content-blocks/basic-container';
import CustomerFeatureBenefit from 'components/content-blocks/customer-feature-benefit';
import QuoteBox from 'components/content-blocks/customer-quote-box';
import CustomerStories from 'components/content-blocks/customer-stories';
import FeaturesGrid from 'components/content-blocks/features-grid';
import { FeaturesList } from 'components/content-blocks/features-list';
import Highlight from 'components/content-blocks/highlight';
import Manager from 'components/content-blocks/manager';
import MediaObject from 'components/content-blocks/media-object';
import MobileFeatures from 'components/content-blocks/mobile-features';
import StructuredData from 'components/content-blocks/partials/structured-data';
import ProductVideo from 'components/content-blocks/product-video';
import Products from 'components/content-blocks/products';
import Awards from 'components/globals/awards';
import MoreIndustries from 'components/globals/more-industries';
import Yoast from 'components/globals/yoast';
import { HeroFeatures } from 'components/hero/features';

import './styles/employee-scheduling-software.scss';

export default function EmployeeSchedulingSoftware() {
  const data = useStaticQuery(graphql`
    {
      allWordpressPage(
        filter: { slug: { eq: "employee-scheduling-software" } }
      ) {
        edges {
          node {
            wordpress_id
            yoast_head_json {
              title
              description
              canonical
              og_title
              og_description
              og_image {
                url {
                  source_url
                }
              }
              og_url
              og_type
              twitter_title
              twitter_description
              twitter_image {
                source_url
              }
            }
            acf {
              hero {
                title
                sub_title
                extra_content
                signup {
                  unique_id
                  modal_title
                  legal_text
                  modal_submit_button_text
                  launch_modal_button_text
                  footer_signup_title
                }
                background_desktop_image {
                  source_url
                  alt_text
                }
              }
              basic_title_subtitle_copy_image {
                copy
              }
              basic_title_subtitle_copy_image_2 {
                title
                copy
                desktop_image {
                  id
                  source_url
                }
                mobile_image {
                  id
                  source_url
                }
              }
              customer_feature_benefit {
                sub_title
                title
                copy
                customer {
                  business
                  name
                  photo_desktop {
                    source_url
                  }
                  photo_mobile {
                    source_url
                  }
                  since
                }
                stats {
                  value_1
                  descriptor_1
                  value_2
                  descriptor_2
                  value_3
                  descriptor_3
                }
              }
              features_grid {
                title
                sub_title
                buttons {
                  title
                  body
                  alt_text
                  icon_width
                  icon_from_library
                  mobile_screenshot {
                    source_url
                  }
                  desktop_screenshot {
                    source_url
                  }
                }
              }
              features_grid_mobile_1 {
                title
                sub_title
                buttons {
                  button_1 {
                    icon_from_library
                    icon_width
                    title
                    content_list {
                      title
                      list_items
                    }
                    desktop_screenshot {
                      source_url
                    }
                    mobile_screenshot {
                      source_url
                    }
                  }
                  button_2 {
                    icon_from_library
                    icon_width
                    title
                    content_list {
                      title
                      list_items
                    }
                    desktop_screenshot {
                      source_url
                    }
                    mobile_screenshot {
                      source_url
                    }
                  }
                  button_3 {
                    icon_from_library
                    icon_width
                    title
                    content_list {
                      title
                      list_items
                    }
                    desktop_screenshot {
                      source_url
                    }
                    mobile_screenshot {
                      source_url
                    }
                  }
                  button_4 {
                    icon_from_library
                    icon_width
                    title
                    content_list {
                      title
                      list_items
                    }
                    desktop_screenshot {
                      source_url
                    }
                    mobile_screenshot {
                      source_url
                    }
                  }
                }
              }
              cards_1 {
                header {
                  title
                  sub_title
                }
                card {
                  header
                  image {
                    source_url
                  }
                  title
                  copy
                  link_url
                  link_text
                  is_active
                }
              }
              cards_2 {
                header {
                  title
                  sub_title
                }
                card {
                  header
                  image {
                    source_url
                  }
                  title
                  copy
                  link_url
                  link_text
                  is_active
                }
              }
              customer_quote_1 {
                company_info
                desktop_image {
                  source_url
                }
                mobile_image {
                  source_url
                }
                quote
              }
              video_block_1 {
                video_id
                image_desktop {
                  source_url
                }
                image_mobile {
                  source_url
                }
              }
              faq {
                question
                answer
              }
              media_object {
                body
                sub_title
                title
                button_url
                button_text
                desktop_image {
                  alt_text
                  source_url
                }
                mobile_image {
                  alt_text
                  source_url
                }
                wysiwyg
              }
              customer_stories {
                card_groups {
                  card {
                    card_link
                    card_link_title
                    sub_title
                    title
                    image {
                      alt_text
                      source_url
                    }
                  }
                }
                card_section_title
                section {
                  copy
                  sub_title
                  title
                }
              }
              feature_manager_overview {
                copy
                title
                sub_title
                app_logos {
                  app_store {
                    source_url
                    alt_text
                  }
                  google_play {
                    source_url
                    alt_text
                  }
                }
                actions {
                  cards {
                    copy
                    phone_screen {
                      source_url
                      alt_text
                    }
                  }
                  mobile_card {
                    phone_screen {
                      source_url
                      alt_text
                    }
                  }
                  title
                }
                face_icons {
                  center {
                    source_url
                    alt_text
                  }
                  right {
                    source_url
                    alt_text
                  }
                  left {
                    source_url
                    alt_text
                  }
                }
                phone_case_background {
                  left_and_right {
                    source_url
                    alt_text
                  }
                  center {
                    source_url
                    alt_text
                  }
                }
              }
              features_list {
                sub_title
                title
                items {
                  icon
                  body
                  desktop_image {
                    alt_text
                    source_url
                  }
                  mobile_image {
                    source_url
                    alt_text
                  }
                  title
                }
              }
            }
          }
        }
      }
      allWordpressAcfOptions {
        edges {
          node {
            options {
              structured_data {
                product_employee_scheduling_software
                faq {
                  employee_scheduling_software
                }
              }
              awards {
                background_image_desktop {
                  url {
                    source_url
                  }
                }
                background_image_mobile {
                  url {
                    source_url
                  }
                }
                content
                sub_title
                title
                main_image_desktop {
                  url {
                    source_url
                  }
                }
                main_image_mobile {
                  url {
                    source_url
                  }
                }
              }
              industries {
                industries {
                  url
                  title
                  icon {
                    alt_text
                    source_url
                  }
                }
              }
            }
          }
        }
      }
    }

  `);

  const pageContext = data.allWordpressPage.edges[0].node.acf;
  const hero = pageContext.hero;
  const basicTitleSubtitleCopyImage = pageContext.basic_title_subtitle_copy_image;
  const allInOne = pageContext.basic_title_subtitle_copy_image_2;
  const awards = data.allWordpressAcfOptions.edges[0].node.options.awards;
  const productVideo = pageContext.video_block_1;
  const mediaObject = pageContext.media_object;
  const customerStories = pageContext.customer_stories;
  const faq = pageContext.faq;
  const faqStructuredData = data.allWordpressAcfOptions.edges[0].node.options.structured_data.faq.employee_scheduling_software;
  const cards1 = pageContext.cards_1;
  const cards2 = pageContext.cards_2;
  const customerQuote = pageContext.customer_quote_1;
  const customerCard = pageContext.customer_feature_benefit;
  const featuresGrid = pageContext.features_grid;
  const mobileFeatures = pageContext.features_grid_mobile_1;
  const appFeatures = pageContext.features_list;
  const featureManagerOverview = pageContext.feature_manager_overview;
  const structuredData = data.allWordpressAcfOptions.edges[0].node.options.structured_data.product_employee_scheduling_software;
  const moreIndustries = data.allWordpressAcfOptions.edges[0].node.options.industries;

  return (
    <Layout
      faq={ faq }
      footerCtaTitle={ pageContext.hero.signup.footer_signup_title }
      modalTitle={ pageContext.hero.signup.modal_title }
      legalText={ hero.signup.legal_text }
      faqStructuredData={ faqStructuredData }
    >
      <Yoast { ...data.allWordpressPage.edges[0].node.yoast_head_json } />
      <div className="employee-scheduling-software-page-container"
        data-signal={ data.allWordpressPage.edges[0].node.wordpress_id }>
        <HeroFeatures
          { ...hero }
          uniqueId="schedulingFeature"
          section="featuresSchedulingHero"
        />
        {basicTitleSubtitleCopyImage.copy !== '' && <section className="section basic-copy-section"><p
          className="has-text-centered">{basicTitleSubtitleCopyImage.copy}</p></section>}
        <ProductVideo { ...productVideo } />
        <Highlight { ...cards1 } hasImage hasTitle titleAsHeader />
        <Awards { ...awards } category="scheduling" />
        <BasicContainer { ...allInOne } lightMode />
        <Manager { ...featureManagerOverview[0] } />
        <MediaObject { ...mediaObject[2] } className="features-payroll reverse" />
        <MediaObject { ...mediaObject[0] } />
        <MediaObject { ...mediaObject[1] } className="reverse" />
        <MediaObject { ...mediaObject[3] } className="more-padding" />
        <CustomerFeatureBenefit { ...customerCard[0] } />
        <FeaturesList { ...appFeatures } />
        <FeaturesGrid { ...featuresGrid[0] } className="reverse" />
        <QuoteBox { ...customerQuote } />
        <MobileFeatures { ...mobileFeatures } className="reverse" />
        <FeaturesGrid { ...featuresGrid[1] } className="reverse" />
        <CustomerStories { ...customerStories } hasNoIndustryTitles />
        <MoreIndustries { ...moreIndustries } />
        <Products
          { ...cards2 }
          modalTitle={ hero.modal_title }
          startingIndex={ 1 }
          hasHeadline
          hasTitle
          activeIndex={ 1 }
        />
      </div>
      <StructuredData data={ structuredData } />
    </Layout>
  );
}
